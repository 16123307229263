import request from '@/utils/axios'

export function getInfoByOrder(data: any) {
  return request({
    url: '/business/selectOrderByOrderNo',
    method: 'get',
    params: data
  })
}

export function getInfoByTransport(data: any) {
  return request({
    url: '/business/selectOrderByTransportNo',
    method: 'get',
    params: data
  })
}

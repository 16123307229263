import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import { VueClipboard } from '@soerenmartius/vue3-clipboard'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app
  .use(createPinia())
  .use(router)
  .use(ElementPlus, {
    locale: zhCn
  })
  .use(VueClipboard)

app.mount('#app')

import fetch from 'axios'

const axios: any = fetch
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.withCredentials = true
// 根据地址判断使用的api地址
const baseurl = import.meta.env.VITE_BASE_API

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseurl,
  // 超时
  timeout: 500000
})

// request拦截器
service.interceptors.request.use((config: any) => {
  // config.headers['Authorization'] = 'Bearer ' + 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUzNTI5ZWUzLTVhMmMtNDc3MS1iZGM2LWYwODQ5NzU2Y2JiNyJ9.gDDkhHyETpudZSBwMuOiSx5b0Q3GmDhsJlmdXDkDtvzyU1cDYIyiaBAc2P8jN0l56KNXnmqP36OgOfru4wLvqA'
  return config
})

// 响应拦截器
service.interceptors.response.use((res: any) => {
  return res.data
})

export default service

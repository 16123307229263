<template>
    <div class="container">
        <el-row class="header">
            <el-col :span="6" style="margin:0 5px;">
                <el-select v-model="value" placeholder="请选择" @change="selectChange">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="14" style="margin:0 5px;">
                <el-input v-model="input" placeholder="请输入订单号/运单号"></el-input>
            </el-col>
            <el-col :span="2" style="margin:0 5px;">
                <el-button type="primary" @click="search">查询</el-button>
            </el-col>
        </el-row>
        <div class="frame">
            <!-- <el-empty description="暂无数据" v-if="!iframeUrl" /> -->
            <iframe :src="iframeUrl" frameborder="0" id="iframeBox"></iframe>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, toRefs, onMounted, shallowRef } from 'vue';
// import rpcMessage from "@/utils/uesPostMessage";

const state = reactive({
    ikey: new Date().getTime(),
    value: 1,
    options: [
        {
            value: 1, label: '订单号',
        },
        {
            value: 2, label: '运单号'
        }
    ],
    input: '',
    iframeUrl:location.origin + '/noloading',
    joint:'?orderNo=',
})
const selectChange = (e:number) => {
    if(e == 1){
        state.joint = '?orderNo='
    }else if(e == 2){
        state.joint = '?transportNo='
    }else{
        state.joint = ''
    }
}
const search = () => {
    state.ikey = new Date().getTime()
    if(!state.joint){
        ElMessage({
            message: '请先选择订单/运单',
            type: 'warning',
        })
        return
    }
    if(!state.input){
        ElMessage({
            message: '请先输入订单号/运单号',
            type: 'warning',
        })
        return
    }    
    state.iframeUrl = location.origin + state.joint + state.input + '&t=' + state.ikey
    console.log(state.iframeUrl);
    // let iframeBox:any = document.getElementById("iframeBox");
    // iframeBox.contentWindow.postMessage('refresh', '*');
}
const { value, options, input,iframeUrl,ikey } = toRefs(state);
</script>

<style scoped lang="scss">
.container {
    width: 100vw;
    height: 100vh;
}

.header {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 80px;
    margin: 0 auto;
}

.frame {
    width: 90vw;
    height: calc(100vh - 86px);
    margin:0 auto;
    iframe {
        width: 100%;
        height: 100%;
    }
}
</style>
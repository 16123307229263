import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import tiandituView from '../views/AboutView.vue'
import searchView from '../views/searchView.vue'
import noloading from '../views/noloading.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/tianditu',
      name: 'tianditu',
      component: tiandituView
    },{
      path: '/search',
      name: 'search',
      component: searchView
    },{
      path: '/noloading',
      name: 'noloading',
      component: noloading
    }
  ]
})

export default router
